/* eslint-disable no-nested-ternary */
import { keyframes } from '@emotion/core';
import styled from '@emotion/styled';

import * as colors from '@styles/colors';
import { small } from '@styles/media-queries';
import {
  colorBarDesktop,
  colorBarMob,
  contentWidth,
  cursor,
  fonts,
  marginBottom,
  margins,
  marginTop,
  paddingBottom,
  paddingTop,
  textAlignments,
  textDecorations,
} from '@styles/utils';

export const Hero = styled.div({
  minHeight: 591,
  maxHeight: 700,
  backgroundColor: colors.primary,
  color: colors.white,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const ColorBars = styled.div({
  position: 'sticky',
  top: 69,
  width: '100%',
  height: 12,
  zIndex: 1,
  background: colorBarDesktop,

  [small]: {
    height: 8,
    background: colorBarMob,
  },
});

export const SectionTwo = styled.div({
  padding: '75px 20px 90px',
  textAlign: 'center' as any,
  backgroundColor: colors.homepagePink,
});

export const SectionTwoWrapper = styled.div({
  maxWidth: 863,
  margin: '0 auto',
});

export const SectionFour = styled.div({
  padding: '75px 20px 90px',
  textAlign: 'center' as any,
  backgroundColor: colors.homepagePink,

  [small]: {
    padding: '10px 20px 50px',
  },
});

export const SectionFourWrapper = styled.div({
  maxWidth: 863,
  margin: '0 auto',
});

export const AsSeenText = styled.span({
  letterSpacing: '2.99px',
  padding: '0px 30px !important',
  color: colors.primary,
  textTransform: 'uppercase',

  [small]: {
    paddingBottom: '10px !important',
  },
});

export const SectionFive = styled.div({
  padding: '75px 20px 90px',
  textAlign: 'center' as any,
  backgroundColor: colors.white,

  [small]: {
    padding: '10px 20px 50px',
  },
});

export const SectionFiveWrapper = styled.div({
  maxWidth: 863,
  margin: '0 auto',
  display: 'grid',
});

export const PartnersTitleText = styled.span({
  letterSpacing: '1.77px',
  color: colors.primary,
  textTransform: 'uppercase',
  backgroundColor: `${colors.white} !important`,
});

export const PartnersDescText = styled.p([
  fonts.spLight,
  {
    fontSize: 16,
    letterSpacing: '0.18px',
    lineHeight: '25.5px',
    maxWidth: 540,
    margin: '45px auto 0px',
    order: 2,

    [small]: {
      margin: '15px auto 0px',
      order: 3,
    },
  },
]);

export const SectionTwoHeader = styled.h2({
  fontSize: 50,
  marginBottom: 30,
  fontWeight: 'normal' as any,
  fontFamily: 'Raleway',
  color: colors.primary,

  [small]: {
    fontSize: 26,
  },
});

export const SectionPara = styled.p({
  maxWidth: 814,
  marginLeft: 'auto',
  marginRight: 'auto',
  fontSize: 24,
  paddingBottom: 40,
  fontWeight: 100,
  fontFamily: 'sofia-pro',
  letterSpacing: '0.26px',
  lineHeight: '36px',
  color: colors.primary,

  [small]: {
    fontSize: '18px',
    lineHeight: '26px',
    marginBottom: 10,
  },
});

export const ImageRowHeadingDivider = styled.div([
  marginTop.mtFortyFive,
  {
    span: {
      backgroundColor: colors.homepagePink,
      padding: '0 10px',
      fontSize: 40,
      fontWeight: 400,
      lineHeight: '10px',
      fontFamily: 'palmer-lake',
      textTransform: 'uppercase',
      color: colors.primary,
    },
    ':before': {
      position: 'absolute' as any,
      top: '50%',
      transform: 'translateY(-50%)',
      left: 0,
      marginTop: -1,
      content: '""',
      height: 1,
      width: '100%',
      backgroundColor: colors.primary,
    },
    [small]: {
      span: {
        fontSize: 34,
        paddingBottom: 10,
        borderBottom: '1px solid',
      },
      ':before': {
        opacity: 0,
      },
    },
  },
]);
export const BgWhite = {
  span: {
    backgroundColor: 'white',
  },
};

export const MarketingWrapper = styled.div([
  contentWidth.width100p,
  textAlignments.textCenter,
  paddingTop.ptForty,
  paddingBottom.pbSeventy,
  margins.auto,
  marginTop.mTZero,
  marginBottom.mbZero,
  {
    maxWidth: 863,

    [small]: {
      paddingBottom: 50,
      span: {
        fontSize: 26,
        padding: 0,
        display: 'block',
        paddingBottom: 10,
      },
      ':before': {
        top: '100%',
        margin: 0,
      },
    },
  },
]);

export const ImageRow = styled.div({
  width: '100%',
  maxWidth: 863,
  margin: '0 auto',
  justifyContent: 'center',
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  gap: 15,

  [small]: {
    gridTemplateColumns: '1fr',
    gap: 45,
  },
});

export const PressImageRow = styled.div({
  width: '100%',
  maxWidth: 863,
  margin: '0 auto',
  justifyContent: 'center',
  display: 'flex',
  gap: 40,
  paddingTop: 45,

  [small]: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: 10,
    paddingTop: 30,

    '> div': {
      display: 'grid',
      placeContent: 'center',
    },

    image: {
      width: '100% !important',
    },
  },
});

export const PartnersImageRow = styled.div({
  width: '100%',
  maxWidth: 410,
  margin: '0 auto',
  justifyContent: 'center',
  display: 'flex',
  gap: 40,
  order: 3,

  [small]: {
    gap: 20,
    marginTop: -10,
    order: 2,
  },
});

export const ColumnWrapper = styled.div({
  [small]: {
    svg: {
      height: '130px',
    },
  },
});

export const ImageRowHeadingText = styled.h3({
  fontSize: 24,
  fontFamily: 'Raleway',
  lineHeight: 1.2,
  fontWeight: 'bold' as any,
  marginTop: 15,
  marginBottom: 15,
  color: colors.primary,
});

export const ImageRowInfoText = styled.p<{ cMaxWidth?: number }>(props => [
  fonts.spLight,
  {
    margin: 'auto',
    maxWidth: props.cMaxWidth ? props.cMaxWidth : 266,
  },
]);

export const LearnMoreWrap = styled.div({
  textAlign: 'center' as any,
});

export const LearnMoreLink = styled.a({
  width: '100%',
  maxWidth: '300px',
  padding: '0 20px',
  display: 'inline-block',
  lineHeight: '40px',
  letterSpacing: '1.66px',
  fontWeight: 'bold' as any,
  fontFamily: 'brandon-grotesque',
  backgroundColor: colors.pink,
  borderRadius: 8,
  textDecoration: 'none',
  color: colors.primary,
  cursor: 'pointer',
});

export const AppDownloadLinkBtn = styled.div({
  width: '100%',
  maxWidth: '300px',
  padding: '0 20px',
  display: 'inline-block',
  lineHeight: '50px',
  letterSpacing: '1.66px',
  fontWeight: 'bold' as any,
  fontFamily: 'brandon-grotesque',
  backgroundColor: colors.primary,
  borderRadius: 8,
  textDecoration: 'none',
  color: colors.white,
  cursor: 'pointer',
});

export const StoreImageWrapper = styled.div({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',

  [small]: {
    gridTemplateColumns: '1fr',
  },
});

export const StoreImage = styled.img({
  width: '100%',
  verticalAlign: 'middle',
});

export const CenterBlock = styled.div({
  position: 'relative',
  margin: 20,
});

export const CenterBlockText = styled.div({
  textAlign: 'center' as any,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  padding: 40,
});

export const CenterText = styled.div({
  fontSize: 44,
  fontFamily: 'Raleway',
  fontWeight: 400,
  lineHeight: '55px',
  letterSpacing: 0.49,
  textAlign: 'center',
  color: `${colors.primary}`,

  [small]: {
    fontSize: 33,
    lineHeight: '41px',
    letterSpacing: 0.37,
  },
});

export const BookNowButton = styled.button([
  cursor,
  textDecorations.none,
  {
    fontSize: 55,
    letterSpacing: '0.9px',
    fontFamily: 'palmer-lake',
    fontWeight: 400,
    display: 'inline-block',
    border: 'none',
    borderBottom: '2px solid black',
    borderColor: colors.primary,
    color: colors.primary,
    textAlign: 'center' as any,
    background: 'transparent',
  },
]);

export const BlueBar = styled.div<{ whiteBackground: boolean }>(props => ({
  display: 'grid',
  gridTemplateColumns: 'auto auto auto auto',
  gap: 15,
  backgroundColor: props.whiteBackground
    ? `${colors.white}`
    : `${colors.lightBlue}`,
  padding: 30,
  textAlign: 'center' as any,
  justifyContent: 'center',
  alignItems: 'center',

  [small]: {
    gridTemplateColumns: '1fr',
    paddingBottom: 60,
  },
}));

export const blueBarInput = {
  backgroundColor: colors.white,
  padding: '8px 25px 8px',
  textAlign: 'center' as any,
  width: '100%',
  borderRadius: 8,
  border: '1px rgba(0,0,0,0.08) solid',
  outline: 'none',

  [small]: {
    fontSize: 18,
    padding: '10px 25px 10px',
  },
};

export const blueBarBtn = {
  [small]: {
    fontSize: 21,
    fontFamily: 'brandon-grotesque',
    fontWeight: 700,
    fontStyle: 'normal',
    padding: '16px 25px 16px',
    maxHeight: 64,
  },
};

export const GetLinkWrap = styled.div({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  alignItems: 'flex-start',
  justifyContent: 'center',
  gap: 15,

  [small]: {
    gridTemplateColumns: '1fr',
    flexDirection: 'column',
    gap: 35,
  },
});

export const ImgRestrictWrap = styled.div({
  overflowY: 'hidden',
  minHeight: 145,
});

export const GetLinkTitleWrap = styled.div({
  fontFamily: 'palmer-lake',
  fontSize: '38px',
  lineHeight: '32px',
  textTransform: 'uppercase',

  [small]: {
    padding: '0 50px',
    lineHeight: '26px',
    marginBottom: 10,
  },
});

export const AppStoreImageWrap = styled.div({
  paddingLeft: 34,
  display: 'flex',

  [small]: {
    paddingLeft: 0,
    paddingTop: 30,
  },
});

export const fromLeft = keyframes({
  '0%': {
    transform: 'rotate(-90deg)',
    transformOrigin: 'bottom right',
  },
  '100%': {
    transform: 'rotate(0deg)',
    transformOrigin: 'bottom right',
  },
});

export const fromBottom = keyframes({
  ' 0% ': {
    transform: 'scale(1, 1) translateY(100px)',
  },
  '30%': {
    transform: 'scale(0.9, 1.1) translateY(0px)',
  },
  '57%': {
    transform: 'scale(1, 1) translateY(7px)',
  },
  '100%': {
    transform: 'scale(1, 1) translateY(0px)',
  },
});

export const fromRight = keyframes({
  '0%': {
    transform: 'rotate(90deg)',
    transformOrigin: 'bottom left',
  },
  '100%': {
    transform: 'rotate(0deg)',
    transformOrigin: 'bottom left',
  },
});
