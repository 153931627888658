import React from 'react';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import Link from 'next/link';
import VisuallyHidden from '@reach/visually-hidden';

import PartnerLogo1 from '@assets/images/Aetna_logo_Navy.png';
import PressLogo4 from '@assets/images/cheddar_news_logo.png';
import PartnerLogo2 from '@assets/images/Cigna_Logo_Navy.png';
import PressLogo2 from '@assets/images/LATimes_Logo.png';
import ScrollMore from '@assets/images/svg/scroll-more.svg';
import PressLogo3 from '@assets/images/The_Hollywood_Reporter_logo.png';
import PressLogo1 from '@assets/images/UncoverLA_Logo.png';
import { TitleWithLine } from '@components/about/about.styled';
import { LinkTag } from '@components/shared/link';
import useIsMobile from '@hooks/use-is-mobile';
import useScrollToTop from '@hooks/use-scroll-top';
import { HomeComponentProps } from '@models/home';
import useStaticPageSegment from '@services/analytics/use-analytics';
import * as colors from '@styles/colors';
import { cursor, marginBottom, positionAbsolute } from '@styles/utils';

import HeroSlideshow from './hero-slideshow';
import {
  AsSeenText,
  ColorBars,
  Hero,
  ImageRowHeadingDivider,
  LearnMoreLink,
  LearnMoreWrap,
  PartnersDescText,
  PartnersImageRow,
  PartnersTitleText,
  PressImageRow,
  SectionFive,
  SectionFiveWrapper,
  SectionFour,
  SectionFourWrapper,
  SectionPara,
  SectionTwo,
  SectionTwoHeader,
  SectionTwoWrapper,
} from './home.styled';

const AppLinkForm = dynamic(() => import('./app-link-form'));
const BookLocation = dynamic(() => import('./book'));
const MarketingRow = dynamic(() => import('./marketing-row'));

const Home: React.FunctionComponent<HomeComponentProps> = ({
  handleBookNow,
}) => {
  const marketingRowRef = React.useRef<null | HTMLDivElement>(null);
  const { trackStaticPageEvents } = useStaticPageSegment();
  const isMobile = useIsMobile();

  const handleLearnMoreClick = () => {
    trackStaticPageEvents('Home - Learn More', {}, 'track');
  };

  const handleScroll = () => {
    const yOffset = -100;
    const y =
      (marketingRowRef.current?.getBoundingClientRect()?.top || 0) +
      window.pageYOffset +
      yOffset;
    window?.scrollTo({ top: y, behavior: 'smooth' });
  };

  useScrollToTop();

  return (
    <>
      <Hero>
        <HeroSlideshow />
        <button
          type="button"
          css={[
            cursor,
            positionAbsolute.bottomMiddle,
            marginBottom.mbFifteen,
            { background: 'transparent', border: '0px' },
          ]}
          onClick={handleScroll}
        >
          <>
            <VisuallyHidden>Scroll to Details</VisuallyHidden>
            <ScrollMore />
          </>
        </button>
      </Hero>
      <ColorBars />
      <SectionTwo>
        <SectionTwoWrapper>
          <SectionTwoHeader>You talk. We listen. It helps.</SectionTwoHeader>
          <SectionPara>
            Emotions are what make us... well, human! And being okay with them
            is a big part of your emotional and mental wellness. So come in and
            chat with a therapist, and learn to love being human again.
          </SectionPara>
          <div ref={marketingRowRef} />
          <ImageRowHeadingDivider>
            <span>Therapy. The way it should be.</span>
          </ImageRowHeadingDivider>
          <MarketingRow />
          <LearnMoreWrap>
            <Link href="/therapists" passHref>
              <LearnMoreLink
                css={cursor}
                onClick={handleLearnMoreClick}
                role="button"
                tabIndex={0}
              >
                Learn more
              </LearnMoreLink>
            </Link>
          </LearnMoreWrap>
        </SectionTwoWrapper>
      </SectionTwo>
      <BookLocation handleBookNow={handleBookNow} />
      <SectionFour>
        <SectionFourWrapper>
          <TitleWithLine>
            <AsSeenText>AS SEEN IN</AsSeenText>
          </TitleWithLine>
          <PressImageRow>
            <div>
              <Image
                src={PressLogo1}
                alt="PressLogo1"
                width={236}
                height={39}
                objectFit="contain"
              />
            </div>
            <div>
              <Image
                src={PressLogo2}
                alt="PressLogo2"
                width={77}
                height={74}
                objectFit="contain"
              />
            </div>
            <div>
              <Image
                src={PressLogo3}
                alt="PressLogo3"
                width={205}
                height={48}
                objectFit="contain"
              />
            </div>
            <div>
              <Image
                src={PressLogo4}
                alt="PressLogo4"
                width={152}
                height={55}
                objectFit="contain"
              />
            </div>
          </PressImageRow>
        </SectionFourWrapper>
      </SectionFour>
      <SectionFive>
        <SectionFiveWrapper>
          <TitleWithLine>
            <PartnersTitleText>In-Network Partners</PartnersTitleText>
          </TitleWithLine>
          <PartnersDescText>
            Don’t see your insurance carrier? Check out our self-pay rates, as
            well as how we help clients save up to 80% off their sessions
            <Link href="/pricing" passHref>
              <LinkTag style={{ color: colors.primary, marginLeft: 4 }}>
                here
              </LinkTag>
            </Link>
            .
          </PartnersDescText>
          <PartnersImageRow>
            <Image
              src={PartnerLogo1}
              alt="PartnerLogo1"
              width={isMobile ? 126 : 186}
              height={isMobile ? 25 : 37}
              objectFit="contain"
            />
            <Image
              src={PartnerLogo2}
              alt="PartnerLogo2"
              width={isMobile ? 120 : 177}
              height={isMobile ? 68 : 100}
              objectFit="contain"
            />
          </PartnersImageRow>
        </SectionFiveWrapper>
      </SectionFive>
      <AppLinkForm />
    </>
  );
};

export default Home;
