import React from 'react';
import { animated, config, useTransition } from 'react-spring';

import HeroImageOneMob from '@assets/images/svg/home-slider-1-mob.svg';
import HeroImageOne from '@assets/images/svg/home-slider-1.svg';
import HeroImageTwoMob from '@assets/images/svg/home-slider-2-mob.svg';
import HeroImageTwo from '@assets/images/svg/home-slider-2.svg';
import HeroImageThreeMob from '@assets/images/svg/home-slider-3-mob.svg';
import HeroImageThree from '@assets/images/svg/home-slider-3.svg';
import HeroImageFourMob from '@assets/images/svg/home-slider-4-mob.svg';
import HeroImageFour from '@assets/images/svg/home-slider-4.svg';
import useIsMobile from '@hooks/use-is-mobile';
import { small } from '@styles/media-queries';

const HeroSlideshow: React.FunctionComponent = () => {
  const [index, setIndex] = React.useState(0);
  const isMobile = useIsMobile();
  const slides = [HeroImageOne, HeroImageTwo, HeroImageThree, HeroImageFour];
  const slidesMob = [
    HeroImageOneMob,
    HeroImageTwoMob,
    HeroImageThreeMob,
    HeroImageFourMob,
  ];

  const transitions = useTransition(index, item => item, {
    from: {
      opacity: 0,
      position: 'absolute',
      top: '40%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.molasses,
  });

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setIndex(state => (state + 1) % 4);
    }, 3000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      {transitions.map(({ item, props, key }) => {
        const Image = isMobile ? slidesMob[item] : slides[item];

        return (
          <animated.div
            key={key}
            style={props}
            css={{
              width: index === 1 ? '70%' : '40%',
              maxWidth: 1250,
              textAlign: 'center',
              [small]: {
                width: '91%',
                maxWidth: 400,
              },
            }}
          >
            {item === index ? <Image /> : null}
          </animated.div>
        );
      })}
    </>
  );
};

export default HeroSlideshow;
