import styled from '@emotion/styled';

import * as colors from '@styles/colors';
import { cursor, fonts, fontSize, textDecorations } from '@styles/utils';

export const LinkTag = styled.a([
  cursor,
  fonts.spMedium,
  textDecorations.underline,
  fontSize.fSixteen,
  {
    color: colors.secondary,
  },
]);
