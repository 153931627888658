import React from 'react';
import { NextPage } from 'next';
import { useRouter } from 'next/router';

import Home from '@components/home';
import Layout from '@components/layout';
import Meta from '@components/meta-tag';
import { ZendeskChatScript } from '@components/script';
import useStaticPageSegment from '@services/analytics/use-analytics';

const HomePage: NextPage = () => {
  const router = useRouter();
  const { trackStaticPageEvents } = useStaticPageSegment();

  React.useEffect(() => {
    trackStaticPageEvents('Home');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    router.prefetch('/book-session/locations');
    router.prefetch('/therapists');
  }, [router]);

  const handleBookNow = () => {
    router.push('/book-session/locations');
  };

  return (
    <>
      <Meta
        titleSuffix="You talk. We listen. It helps. Therapists near me!"
        description="Okay Humans is a new talk therapy / counseling experience focusing on emotional and mental wellness through easy to book sessions with therapists near me."
      />
      <ZendeskChatScript />
      <Layout
        headerProps={{
          menu: true,
          bookLink: true,
          back: false,
          cancel: false,
          headerBorder: false,
        }}
        footerProps={{ showItems: true }}
        fullWidthLayout
      >
        <Home handleBookNow={handleBookNow} />
      </Layout>
    </>
  );
};

export default HomePage;
