import styled from '@emotion/styled';

import imageFrame from '@assets/images/blue-frame.png';
import * as colors from '@styles/colors';
import { small } from '@styles/media-queries';
import { fonts, textAlignments } from '@styles/utils';

export const AboutMediumWrapper = styled.div({
  maxWidth: '735px',
  textAlign: 'center',
  margin: '50px auto',
  [small]: {
    padding: '0 20px',
  },
});

export const AboutFullWrapper = styled.div({
  backgroundColor: colors.homepagePink,
  padding: '20px 0',

  [small]: {
    padding: '20px 0px 20px',

    '> div': {
      marginTop: 0,
    },
  },
});

export const AboutPara = styled.div([
  fonts.spLight,
  fonts.infoPara,
  textAlignments.textCenter,
  {
    margin: 'auto',
    letterSpacing: 0.18,
    [small]: {
      textAlign: 'center',
      fontSize: 18,
    },
  },
]);

export const MediumText = styled.span([
  fonts.spMedium,
  fonts.infoPara,
  textAlignments.textCenter,
  {
    margin: '15px auto',
    display: 'inline-block',
    letterSpacing: 0.18,

    [small]: {
      fontSize: 18,
    },
  },
]);

export const TitleWithLine = styled.div({
  textAlign: 'center',
  span: {
    backgroundColor: colors.homepagePink,
    padding: '0 10px',
    fontSize: 22,
    fontWeight: 500,
    fontFamily: 'brandon-grotesque',
    textTransform: 'uppercase',
  },
  p: {
    backgroundColor: colors.white,
    padding: '0 10px',
    fontSize: '36px',
    lineHeight: '25.5px',
    letterSpacing: '0.9px',
    fontFamily: 'palmer-lake',
    textTransform: 'uppercase',
    width: 'fit-content',
    margin: 'auto',
  },
  ':before': {
    position: 'absolute' as any,
    top: '50%',
    left: 0,
    content: '""',
    height: 1,
    width: '100%',
    backgroundColor: 'rgba(0,0,0,0.08)',
  },
  svg: {
    backgroundColor: colors.white,
    width: '100%',
    maxWidth: '250px',
    padding: '0 20px',
  },
  [small]: {
    marginBottom: 20,
    borderBottom: '1px solid rgba(151,151,151,0.23)',
    span: {
      fontSize: 18,
      padding: 0,
      display: 'block',
      paddingBottom: 10,
      marginTop: 20,
    },
    ':before': {
      display: 'none',
    },
    svg: {
      paddingBottom: 10,
    },
    p: {
      marginBottom: 20,
    },
  },
});

export const AboutGrid = styled.div({
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  gap: 45,
  marginTop: 30,
  marginBottom: 50,
  justifyContent: 'center',
  alignItems: 'center',
  [small]: {
    gridTemplateColumns: '1fr',
  },
});

export const mobPadding = {
  [small]: {
    padding: '0 65px !important',
  },
};

export const ImgWithStyledBorder = styled.div({
  width: '100%',
  img: {
    width: '100%',
    zIndex: 1,
    borderRadius: 4,
  },

  '> div': {
    overflow: 'visible !important',
  },

  '> div:before': {
    content: '""',
    position: 'absolute' as any,
    top: '3px',
    left: '10px',
    width: '100%',
    height: '100%',
    background: `url(${imageFrame}) no-repeat bottom right / cover`,
    pointerEvents: 'none' as any,
    fill: 'black',
  },
});
